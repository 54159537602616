<template>
  <div class="container">
    <van-nav-bar>
      <template #left>
        <!-- <van-icon name="info" class="left_icon" size="25" @click="setExit" /> -->
        <span style="display: inline-block; width: 25px; height: 25px;" class="close_logo" @click="setExit"></span>
        <!-- <van-image width="25" height="25" @click="setExit" src=" https://xiangquyouxuan.oss-cn-hangzhou.aliyuncs.com/xiangqu/cancer/internally/1349179c757142279f5a93597e77520a.png" /> -->
      </template>
      <template #right>
        <van-icon name="manager" size="25" @click="alterPass" />
      </template>
    </van-nav-bar>
    <div class="index_section">
      <div class="index_logo"></div>
      <div class="welcome_cs">
        <p class="welcome_1">welcome</p>
        <p class="welcome_2">欢迎入驻</p>
        <p class="welcome_3">{{username[0]}}</p>
        <p class="welcome_4">{{username[1]}}</p>
      </div>
      <div class="index_footer"></div>
    </div>
    <div class="account-D1">
      <van-swipe-cell icon='arrow' v-if='false'>
        <van-cell>
          <van-card :desc="'账号:'+accountNum" :title="username" class="goods-card" :thumb="require('../../assets/img/logo1.png')" />
          <van-image width="30" height="30" class="left_imgs" :src="require('../../assets/img/left.png')" />
        </van-cell>
        <template #right>
          <van-button square text="修改密码" type="info" @click="alterPass" class="delete-button" />
          <!-- <van-button square text="退出登录" type="danger" @click="logOut" class="delete-button" /> -->
        </template>
      </van-swipe-cell>
      <van-popup v-model="show" position="bottom" :style="{ height: '60%' }">
        <template>
          <div class="pass_title">
            <van-nav-bar title="修改登录密码" />
          </div>
          <van-form :model='editpwdForm'>
            <van-field v-model="editpwdForm.oldpwd" :type="passwordOff0?'password':'text'" placeholder='请输入原始密码' @input="$emit('input',password)">
              <template slot="right-icon">
                <img :src="passwordOff0?require('../../assets/img/eye-off.png'):require('../../assets/img/eye-on.png')" width="30px" height="30px" alt="" @click="passwordOff0=!passwordOff0">
              </template>
            </van-field>
            <van-field v-model="editpwdForm.newpwd" :type="passwordOff1?'password':'text'" placeholder='请输入新密码' @input="$emit('input',password)">
              <template slot="right-icon">
                <img :src="passwordOff1?require('../../assets/img/eye-off.png'):require('../../assets/img/eye-on.png')" width="30px" height="30px" alt="" @click="passwordOff1=!passwordOff1">
              </template>
            </van-field>
            <van-field v-model="editpwdForm.newpwd2" :type="passwordOff2?'password':'text'" placeholder='请确认新密码' @input="$emit('input',password)">
              <template slot="right-icon">
                <img :src="passwordOff2?require('../../assets/img/eye-off.png'):require('../../assets/img/eye-on.png')" width="30px" height="30px" alt="" @click="passwordOff2=!passwordOff2">
              </template>
            </van-field>
          </van-form>
          <div class="btnList" style="text-align: center">
            <van-button type="danger" @click="saveEditpwdForm">修改</van-button>
            <van-button type="info" @click="closeClick">取消</van-button>
          </div>
        </template>
      </van-popup>
    </div>
    <van-tabbar route>
      <van-tabbar-item to="/index" icon="wap-home">首页</van-tabbar-item>
      <van-tabbar-item to="/product" icon="gift-card">产品库</van-tabbar-item>
      <van-tabbar-item to="/brand" icon="card">品牌库</van-tabbar-item>
      <van-tabbar-item to="/productAudit" icon="wap-nav">产品提报库</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Notify } from 'vant'
import { Dialog } from 'vant'

let posswordReg = /^(\w){6,18}$/ //只能输入6-18位字母、数字、下划线
import bus from './bus.js'
export default {
  name: 'Index',
  accountNum: '-------',
  model: {
    prop: 'inputValue',
    event: 'input'
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      titleVal: '首页',
      passwordOff0: false,
      passwordOff1: false,
      passwordOff2: false,
      editpwdForm: {
        oldpwd: '', //原始密码
        newpwd: '', //新密码
        newpwd2: '' //二次确认新密码
      },
      show: false,
      password: this.inputValue,
      passwordType: 'password',

      name: '全息之眼-～～～～～～～～～'
    }
  },
  computed: {
    username() {
      let username = this.$cookies.get('ms_username')
      if (username != null || username != undefined) {
        username = username.split('-')
      }
      return username != null ? username : this.name
    },
    accountNum() {
      let accountNum = this.$cookies.get('ms_phone')
      return accountNum ? accountNum : this.accountNum
    }
  },
  methods: {
    closeClick() {
      this.show = false
    },
    saveEditpwdForm() {
      if (!posswordReg.test(this.editpwdForm.oldpwd)) {
        Notify({ type: 'danger', message: '旧密码输入格式错误' })
        return false
      }
      if (!posswordReg.test(this.editpwdForm.newpwd)) {
        Notify({ type: 'danger', message: '新密码输入格式错误' })
        return false
      }
      if (this.editpwdForm.oldpwd == this.editpwdForm.newpwd) {
        Notify({ type: 'danger', message: '新密码和旧密码不能相同' })
        return false
      }
      if (this.editpwdForm.newpwd != this.editpwdForm.newpwd2) {
        Notify({ type: 'danger', message: '两次密码输入不一致' })
        return false
      }
      this.$http
        .ajax(
          '/ffkj-main/merchant/updatePassword',
          {
            oldPassword: this.$md5(this.editpwdForm.oldpwd),
            newPassword: this.$md5(this.editpwdForm.newpwd2),
            phone: window.localStorage.getItem('ms_phone')
          },
          'post',
          {}
        )
        .then(res => {
          if (res.code == 0) {
            Notify({ type: 'success', message: '密码修改成功' })
            // 成功
            setTimeout(
              function () {
                this.toLogin()
              }.bind(this),
              500
            )
          } else {
            Notify({ type: 'danger', message: res.message })
          }
        })
    },
    // 退出去登录
    toLogin() {
      window.localStorage.clear()
      this.$store.commit('saveMerchantId', '')
      this.$store.commit('saveOperator', '')
      this.$store.commit('saveBranchId', '')
      this.$store.commit('saveServiceType', '')
      this.$store.commit('saveIsEfs', 0)
      this.$store.commit('saveResources', '')
      this.$cookies.remove('resources')
      this.$cookies.remove('ms_username')
      this.$cookies.remove('ms_phone')
      this.$cookies.remove('serviceType')
      this.$cookies.remove('isEfs')
      this.$router.push('/')
    },
    logOut() {
      Dialog.confirm({
        title: '退出登录',
        message: '您确定要退出当前账号吗?'
      })
        .then(() => {
          // 确定退出
          this.$router.push({
            path: '/login',
            query: {}
          })
        })
        .catch(() => {
          // 取消
        })
    },
    // 退出登录
    setExit(){
        Dialog.confirm({
          title: '退出登录',
          message: '您确定要退出当前账号吗?',
        })
          .then(() => {
            this.$http
              .ajax(
                '/ffkj-main/merchant/unbind',
                {
                  openId: window.localStorage.openId
                },
                'get',
                {}
              )
              .then(res => {
                if (res.code == 0) {
                  this.$router.push('/login')
                } else {
                  Notify({ type: 'danger', message: '退出登录失败!!!' })
                }
              })
          })
          .catch(() => {
          });
    },
    alterPass() {
      this.show = true
    },
    switchPasswordType1() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    switchPasswordType2() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    switchPasswordType3() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    }
  }
}
</script>
<style scoped>
.goods-card {
  margin: 0;
  background-color: white;
}
.delete-button {
  height: 100% !important;
  width: 58px;
}
::v-deep .van-nav-bar__content{
  background-color: rgba(0, 0, 0, 0)
}
.van-icon {
  line-height: 607%;
  position: relative;
  left: 10px;
  padding-right: 10px;
}
.welcome_cs {
  color: #133676;
}
.welcome_1 {
  font-size: 27px;
  margin-top: 40px !important;
}
.welcome_2 {
  font-size: 16px;
  margin-bottom: 110px !important;
}
.welcome_3 {
  font-size: 16px;
}
.welcome_4 {
  font-size: 16px;
  margin-top: 10px !important;
}
.welcome_cs > p {
  position: relative;
  top: 25vh;
  text-align: center;
  margin: 0;
}
.van-card__title {
  max-height: 45px !important;
  font-weight: 800 !important;
  font-size: 15px;
  line-height: 20px !important;
  margin: 13px 0 14px 0;
}
.index_logo {
  width: 139px;
  height: 143px;
  background: url('../../assets/img/index_logo.png') no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
  position: relative;
  top: 18vh;
}
.van-card__desc {
  font-size: 12px;
}
::v-deep .van-nav-bar__text {
  color: black;
}
::v-deep .van-field__right-icon {
  display: inline;
  margin-top: 11px;
}
.left_icon {
  position: relative;
  left: -5px;
  top: 3px;
}
.index_section {
  height: 100vh;
  width: 100%;
  background: url('../../assets/img/index_back.jpg') no-repeat;
  background-size: 100% 100%;
}
::v-deep .van-field__body {
  border: 1px solid #ccc !important;
  padding: 3px 6px;
}
.btnList > button {
  padding: 4px 15px;
  margin: 10px 10px 0px;
}
.van-cell__value {
  position: relative;
}
.left_imgs {
  position: absolute;
  right: 5px;
  top: 50px;
}
::v-deep .van-nav-bar__content {
  position: fixed;
  height: 30px;
  width: 100%;
}
.pass_title {
  height: 30px;
}
.close_logo{
  background-image: url('../../assets/img/close_logo.jpg');
  background-size: 100% 100%;
}
</style>